export default function collapseComponent(options) {
    var settings = $.extend({
      triggerClass: ".collapse-trigger"
    }, options);
  
    $(document).on("click", settings.triggerClass, function() {
      $(this).next().slideToggle();
      $(this).toggleClass("active");
      return false;
    });
  }