const blogInfoDetailSlider = () => {
    const blogInfoThumbnailSlider = new Swiper(
        ".blog-info-detail-thumbnail .swiper-container",
        {
            spaceBetween: 8,
            slidesPerView: 5,
            observer: true,
            observeParents: true,
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            direction: 'vertical',
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                    direction: "horizontal"
                }
            },
            navigation: {
                prevEl: ".blog-info-detail-thumbnail .swiper-prev",
                nextEl: ".blog-info-detail-thumbnail .swiper-next"
            }
        }
    );
    const blogInfoImageSlider = new Swiper(
        ".blog-info-detail-images .swiper-container",
        {
            effect: "fade",
            fadeEffect: { crossFade: true },
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: blogInfoThumbnailSlider,
            },
            on: {
                slideChange: function () {
                    let activeIndex = this.activeIndex + 1;

                    let nextSlide = $(
                        `.blog-info-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex + 1
                        })`
                    );
                    let prevSlide = $(
                        `.blog-info-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex - 1
                        })`
                    );

                    if (
                        nextSlide &&
                        !nextSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slideNext();
                    } else if (
                        prevSlide &&
                        !prevSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slidePrev();
                    }
                },
            },
        }
    );

    return {
        blogInfoThumbnailSlider,
        blogInfoImageSlider,
    };
};

const useBlogInfoDetail = () => {
    return {
        blogInfoDetailSlider,
    };
};

export default useBlogInfoDetail;
