const productSliderInit = () => {
    $(".product-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 4;
        }
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-slider-next-" + index);

        var productSlider = new Swiper(
            ".product-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".product-slider-prev-" + index,
                    nextEl: ".product-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 15,
                        freeMode: true,
                    },
                    768: {
                        slidesPerView: 1.5,
                        spaceBetween: 10,
                        freeMode: true,
                    },
                },
            }
        );
    });
};

const blogSliderInit = () => {
    $(".blog-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 4;
        }
        $this.addClass("blog-slider-" + index);
        $this.find(".swiper-prev").addClass("blog-slider-prev-" + index);
        $this.find(".swiper-next").addClass("blog-slider-next-" + index);

        var blogSlider = new Swiper(
            ".blog-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".blog-slider-prev-" + index,
                    nextEl: ".blog-slider-next-" + index,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 15,
                        freeMode: true,
                    },
                    768: {
                        slidesPerView: 1.5,
                        spaceBetween: 10,
                        freeMode: true,
                    },
                },
            }
        );
    });
};

const blogInfoCateSlider = new Swiper(".info-cate-slider .swiper-container", {
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: {
        prevEl: ".info-cate-slider .swiper-prev",
        nextEl: ".info-cate-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 10,
            freeMode: true,
        },
    },
});

const tipSlider = new Swiper(".tips-slider .swiper-container", {
    slidesPerView: 5,
    spaceBetween: 20,
    navigation: {
        prevEl: ".tips-slider .swiper-prev",
        nextEl: ".tips-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            freeMode: true,
        },
    },
});

const blogCornerSlider = new Swiper(".blog-corner-slider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 60,
    navigation: {
        prevEl: ".blog-corner-slider .swiper-prev",
        nextEl: ".blog-corner-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            freeMode: true,
        },
    },
});

const blogDiscoverCateSlider = new Swiper(
    ".blog-discover-cate-slider .swiper-container",
    {
        slidesPerView: 5,
        spaceBetween: 20,
        navigation: {
            prevEl: ".blog-discover-cate-slider .swiper-prev",
            nextEl: ".blog-discover-cate-slider .swiper-next",
        },
        breakpoints: {
            1024: {
                slidesPerView: 3.5,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 1.5,
                spaceBetween: 15,
                freeMode: true,
            },
        },
    }
);

const projectDetailGallery = new Swiper(
    ".project-detail-gallery .swiper-container",
    {
        centeredSlides: true,
        spaceBetween: -640,
        slidesPerView: 3.5,
        initialSlide: 2,
        navigation: {
            prevEl: ".project-detail-gallery .swiper-prev",
            nextEl: ".project-detail-gallery .swiper-next",
        },
        breakpoints: {
            1024: {
                slidesPerView: 1.5,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 1.25,
                spaceBetween: 15,
            },
        },
    }
);

const projectDetail = new Swiper(".project-slider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 20,
    navigation: {
        prevEl: ".project-slider .swiper-prev",
        nextEl: ".project-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 1.25,
            spaceBetween: 15,
            freeMode: true,
        },
    },
});

const serviceGallerySlider = new Swiper(
    ".service-gallery-slider .swiper-container",
    {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
            prevEl: ".service-gallery-slider .swiper-prev",
            nextEl: ".service-gallery-slider .swiper-next",
        },
        breakpoints: {
            1024: {
                slidesPerView: 2.5,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 1.25,
                spaceBetween: 15,
                freeMode: true,
            },
        },
    }
);

const awardDetail = new Swiper(".award-slider .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: ".award-slider .swiper-prev",
        nextEl: ".award-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            freeMode: true,
        },
        576: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            freeMode: true,
        },
    },
});

const partnerSliderInit = () => {
    if ($(".partner-slider").data("slides") !== undefined) {
        var initSlide = parseInt($(".partner-slider").data("slides"));
    } else {
        var initSlide = 4;
    }

    const partnerSlide = new Swiper(".partner-slider .swiper-container", {
        slidesPerView: initSlide,
        slidesPerColumn: 2,
        spaceBetween: 20,
        navigation: {
            prevEl: ".partner-slider .swiper-prev",
            nextEl: ".partner-slider .swiper-next",
        },
        breakpoints: {
            1024: {
                slidesPerView: 3.5,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 2.5,
                spaceBetween: 15,
                freeMode: true,
            },
            576: {
                slidesPerView: 1.5,
                spaceBetween: 15,
                freeMode: true,
            },
        },
    });
};

const homePlantSlider = new Swiper(".home-plant-slider .swiper-container", {
    slidesPerView: 2,
    spaceBetween: 70,
    navigation: {
        prevEl: ".home-plant-slider .swiper-prev",
        nextEl: ".home-plant-slider .swiper-next",
    },
    breakpoints: {
        1280: {
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            freeMode: true,
        }
    },
});

const useSlider = () => {
    return {
        productSliderInit,
        blogSliderInit,
        partnerSliderInit,
    };
};

export default useSlider;
