export default function backToTop(ms = 800) {
    var isAnimating = false;
    $(".back-to-top").click(function () {
        if (isAnimating) {
            // do nothing if the button is already being animated
            return false;
        }

        // set the flag to indicate that the button is being animated
        isAnimating = true;

        // perform the scroll animation
        $("html, body").animate({ scrollTop: 0 }, ms, function () {
            // reset the flag when the animation is complete
            isAnimating = false;
        });

        return false;
    });
};
