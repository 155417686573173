const sideNavSubMenuToggle = () => {
    $(".side-nav-menu .has-accordion").each(function () {
        let _this = $(this)
        let toggleEle = _this.find(".accordion-toggle")
        let subEle = _this.find(".accordion-menu")
        let allSiblingsMenu = $(".side-nav-menu .has-accordion .accordion-menu").not(subEle)
        let allSiblingsToggle = $(".side-nav-menu .has-accordion .accordion-toggle").not(toggleEle)

        $(toggleEle).on("click", function(){
            $(toggleEle).toggleClass('active')
            $(subEle).slideToggle()
            $(allSiblingsMenu).slideUp()
            $(allSiblingsToggle).removeClass('active')
        })
    });
};

const sideNavMenuToggle = () => {
    $(".side-nav-heading").on("click", function(){
        $(this).next().slideToggle()
    })
}

const useSidenav = () => {
    return {
        sideNavSubMenuToggle,
        sideNavMenuToggle
    }
};

export default useSidenav

