const APP = {
    detectMobile: function () {
        if ($(window).width() < 1025) {
            return true; // Mobile device detected
        } else {
            return false; // Desktop or tablet detected
        }
    },
};

import headerInit from "./headerInit";
import backToTop from "./backToTop";
import useSidenav from "./sideNavInit";
import useProductDetail from "./productDetailInit";
import useSlider from "./swiperInit";
import useBlogInfoDetail from "./blogDetailInit";
import collapseComponent from "./collapseComponent";

var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
});

const { sideNavSubMenuToggle, sideNavMenuToggle } = useSidenav();
const { productDetailSlider, spinInput } = useProductDetail();
const { productSliderInit, blogSliderInit, partnerSliderInit } = useSlider();
const { blogInfoDetailSlider } = useBlogInfoDetail();

jQuery(function () {
    headerInit();
    backToTop();
    sideNavSubMenuToggle();
    productDetailSlider();
    spinInput();
    productSliderInit();
    blogSliderInit();
    blogInfoDetailSlider();
    partnerSliderInit()

    if (APP.detectMobile()) {
        sideNavMenuToggle();
    }

    // Global JS
    $(".result-list li").each((index, element) => {
        let _this = $(element);
        _this.on("click", () => {
            $("#review-list").html(_this.clone());
        });
    });
    if ($(window).width() < 768) {
        $(".sub-nav-mobile").on("click", function () {
            $(this).next().slideToggle();
            $(this)
                .find(".toggle span")
                .toggleClass("fa-chevron-down fa-chevron-up");
        });
    }

    collapseComponent();
});
