const productDetailSlider = () => {
    const productThumbnailSlider = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            spaceBetween: 16,
            slidesPerView: 6,
            observer: true,
            observeParents: true,
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                768: {
                    slidesPerView: 4
                }
            },
            navigation: {
                prevEl: ".product-detail-thumbnail .swiper-prev",
                nextEl: ".product-detail-thumbnail .swiper-next"
            }
        }
    );
    const productImageSlider = new Swiper(
        ".product-detail-images .swiper-container",
        {
            effect: "fade",
            fadeEffect: { crossFade: true },
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: productThumbnailSlider,
            },
            on: {
                slideChange: function () {
                    let activeIndex = this.activeIndex + 1;

                    let nextSlide = $(
                        `.product-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex + 1
                        })`
                    );
                    let prevSlide = $(
                        `.product-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex - 1
                        })`
                    );

                    if (
                        nextSlide &&
                        !nextSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slideNext();
                    } else if (
                        prevSlide &&
                        !prevSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slidePrev();
                    }
                },
            },
        }
    );

    return {
        productThumbnailSlider,
        productImageSlider,
    };
};

const spinInput = () => {
    $(".btn-spin").click(function () {
        var $button = $(this);
        var type = $(this).data("spin");
        var oldValue = $button.parent().find("input").val();

        if (type == "inc") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        $button.parent().find("input").val(newVal);
        //   AjaxCart.updatecart();
    });
}

const useProductDetail = () => {
    return {
        productDetailSlider,
        spinInput
    };
};

export default useProductDetail;
